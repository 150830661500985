<template>
  <v-container>
      <v-row class="fill-height" align="center" justify="center">

        <!-- Loading -->
        <v-col cols="12">
          <v-row align="center" justify="center" class="fill-height">
            <v-progress-circular
              v-if="isLoading"
              :size="70"
              :width="7"
              color="primary"
              indeterminate>
            </v-progress-circular>
          </v-row>
        </v-col>

        <!-- Success -->
        <v-col cols="12" v-if="isValidated">
          <p class="text-center">
            <v-icon size="100" color="success">fal fa-check-circle</v-icon>
          </p>
        </v-col>

        <v-col cols="12" v-if="isValidated" class="mt-4">
          <div class="text-center font-size-20 MontserratMedium">{{$t('account.confirm-registration.success')}}</div>
        </v-col>

        <v-col cols="12" v-if="isValidated" class="mt-4 text-center">
          <v-btn class="btn-primary" color="primary" @click="() => $router.push({name: 'Home'})">{{$t('account.confirm-registration.buttons.login')}}</v-btn>
        </v-col>

        <!-- Error -->
        <v-col cols="12" v-if="isValidated === false">
          <p class="text-center">
            <v-icon size="100" color="error">fal fa-exclamation-circle</v-icon>
          </p>
        </v-col>

        <v-col cols="12" v-if="isValidated === false && error === 404" class="mt-4">
          <p class="text-center MontserratMedium font-size-20" v-html="$t('account.confirm-registration.errors.404')"/>
        </v-col>

        <v-col cols="12" v-if="isValidated === false && error !== 404" class="mt-4">
          <p class="text-center MontserratMedium font-size-20" v-html="$t('account.confirm-registration.errors.500')"/>
        </v-col>

      </v-row>
    </v-container>
</template>

<script>
  export default {

    name: "ConfirmRegistration",

    components: {

    },

    data() {

      return {
        isValidated: null,
        isLoading: false,
        error: null
      }
    },

    created() {
      this.$store.commit("pageHead/setTitle", this.$t('account.confirm-registration.pageTitle'));
    },

    mounted() {
      this.goValidate();
    },

    methods: {

      goValidate() {
        this.isLoading = true;

        this.$http.post(`/map/users/${this.$route.query.token}/confirmation`, {})
        .then(res => {
          this.isLoading = false;
          this.isValidated = true;
        })
        .catch(err => {
          this.isLoading = false;
          this.isValidated = false;
          this.error = (err && err.response && err.response.status === 404) ? 404 : 500;
        });
      }
    }
  }
</script>

<style scoped>

</style>